
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'

/**
 * 基础工具面版
 */
@Component({
  name: 'toolPanel'
})
export default class ToolPanel extends Vue {
  /**
   * 面版本开关
   */
  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  expended!: boolean

  /**
   * 标题
   */
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  title!: string

  curExpended=true
  /// 开关
  public taggle () {
    this.curExpended = !this.curExpended
  }

  created () {
    this.curExpended = this.expended
  }

  /**
   * 当外部强制指定开关时
   */
  @Watch('expended')
  watchExpended (ex: boolean) {
    this.curExpended = ex
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onclick () {
  }
}
