
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { BaseModal } from '@/libs/modal-helper'
import { Form } from 'view-design'
import PropertyModal from '../property-panel/modal/property-modal.vue'
import TreeNode from './org-tree-node'

@Component({
  name: 'editOrgNodeModal',
  components: { PropertyModal }
})
export default class EditOrgNodeModal extends Vue implements BaseModal {
  /// 当前节点
  @Prop({
    type: Object,
    required: true
  })
  value!: TreeNode

  @Prop({
    type: String,
    required: false,
    default: () => ''
  })
  title!: string

  /// 当前值
  curValue = this.value

  /// 表单约束
  rules = {
    name: [
      {
        required: true,
        type: 'string',
        message: '名称不能为空',
        trigger: 'blur'
      },
      {
        message: '只能字母开头，包含数字、小写字母、下划线并用.分隔',
        pattern: '^[a-z][a-z0-9_]*(.[a-z0-9_]+)+[0-9a-z_]$',
        trigger: 'blur'
      }
    ],
    title: [
      {
        required: true,
        type: 'string',
        message: '标题不能为空',
        trigger: 'blur'
      }
    ]
  }

  show (): void {
    ;(this.$refs.dlg as PropertyModal).show()
  }

  close (): void {
    ;(this.$refs.dlg as PropertyModal).close()
  }

  /// 保存处理
  doSave () {
    const form = this.$refs.form as Form

    form.validate(result => {
      if (result && this.curValue) {
        this.$emit('onOk', this.curValue)
      } else {
        this.$Notice.error({
          title: '错误',
          desc: '数据检查失败!'
        })
      }
    })
  }

  /// 取消处理
  doCancel () {
    this.$emit('onCancel')
  }
}
