
import { Prop, Vue, Component } from 'vue-property-decorator'

/**
 * 实体的子项，如字段
 */
@Component({
  name: 'entityItem'
})
export default class EntityItem extends Vue {
  /// 当前是否处于激活状态
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  active!: boolean

  @Prop({
    type: String,
    required: true,
    default: 0
  })
  id!: string

  /// 是否主键
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  isPrimaryKey!: boolean

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  isJoinField!: boolean

  /// 名称
  @Prop({
    type: String,
    required: true
  })
  name!: string

  /// 标题
  @Prop({
    type: String,
    required: true
  })
  title!: string

  /// 类型
  @Prop({
    type: String,
    required: true
  })
  type!: string

  /// 枚举uuid
  @Prop({
    type: String,
    required: true,
    default: ''
  })
  readonly enumUuid!: string

  /// 长度
  @Prop({
    type: Number,
    required: false
  })
  length?: number

  /// 是否可以为空
  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  nullable!: boolean

  @Prop({
    type: Boolean,
    default: false,
    required: false
  })
  readonly disableTooltip!: boolean

  @Prop({
    type: Boolean,
    default: false,
    required: false
  })
  readonly disableToolBar!: boolean

  /// 选择事件
  select () {
    this.$emit('onActive', this.id)
  }

  /// 删除事件
  onDelete () {
    this.$emit('onDelete', this.id)
  }

  onslick () {
    this.$emit('onStartSlick')
  }

  outslick () {
    this.$emit('onStopSlick')
  }

  // 复制字段事件
  onCopy () {
    this.$emit('onCopy')
  }
}
