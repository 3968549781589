
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { BaseModal } from '@/libs/modal-helper'

@Component({
  name: 'propertyModal'
})
export default class PropertyModal extends Vue {
  visiable=false

  /// 宽度
  @Prop({
    type: Number,
    required: false,
    default: 282
  })
  width!: number

  /**
   * 标题
   */
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  title!: string

  /**
   * 标题
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  hideSave?: boolean

  /// 保存数据
  save () {
    this.$emit('save')
  }

  cancel () {
    this.$emit('cancel')
  }

  /// 显示对话框
  show (): void {
    this.visiable = true
  }

  // 关闭对话框
  close () {
    this.visiable = false
  }
}
