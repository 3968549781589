
import { Vue, Component, Prop } from 'vue-property-decorator'
import elementResizeDetectorMaker from 'element-resize-detector'
import { getterEntityModelVersion } from '@/store'
import { showNewConstraintModal } from './property-panel/modal/constraint-modal'

/**
 * 上方的操作按钮条
 */
@Component({
  name: 'actionPanel'
})
export default class ActionPanel extends Vue {
  curwidth?: number = 0
  // erd: any
  erd = elementResizeDetectorMaker()

  @Prop({
    type: Number,
    required: false,
    default: 0
  })
  errorCount!: number

  /**
   * 当前是否只读
   */
  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  readOnly!: boolean

  /**
   * 当前展开状态
   */
  expended = true

  /**
   * 全屏状态
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  fullscreen!: boolean

  /// 当前版本号
  get curVersion () {
    const versionInfo = getterEntityModelVersion()
    if (versionInfo) return versionInfo.title
    return ''
  }

  /**
   * 当window大小改变时调整窗口大小
   */
  onWindowSizeChage () {
    const width = this.$el.parentElement?.offsetWidth!
    this.$nextTick(() => {
      this.curwidth = width
    })
  }

  mounted () {
    this.erd.listenTo(this.$el.parentElement!, this.onWindowSizeChage)
  }

  beforeDestroy () {
    this.erd.removeAllListeners(this.$el.parentElement!)
  }

  doTagge () {
    this.expended = !this.expended
    this.$emit('onExpended', this.expended)
  }

  /// 处理全屏
  doFullScreen () {
    this.$emit('onFullScreen', this.fullscreen)
  }

  // 放大
  doZoomin () {
    this.$emit('onZoomin')
  }

  // 缩小
  doZoomout () {
    this.$emit('onZoomout')
  }

  /**
   * 添加新的实体操作
   */
  addEntity () {
    if (this.readOnly) return
    this.$emit('addNewEntity', 'Entity')
  }

  addLinkEntity () {
    if (this.readOnly) return
    this.$emit('addLinkEntity')
  }

  addBaseEntity () {
    if (this.readOnly) return
    this.$emit('addNewEntity', 'SupperEntity')
  }

  addViewEntity () {
    if (this.readOnly) return
    this.$emit('addNewEntity', 'View')
  }

  delObject () {
    if (this.readOnly) return
    this.$emit('delObject')
  }

  moveObject () {
    if (this.readOnly) return
    this.$emit('moveObject')
  }

  /// 保存实体数据到当前版本
  doSaveToCurVersion (name: string) {
    this.$emit('onSave')
  }

  selectVersion () {
    // 弹对话框来选择当前的版本

    // 通知加载新的版本
    this.$emit('loadModelData', '')
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onclick () {}
}
