
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import ToolPanel from './tool-panel.vue'
import OrgInfoPanel from './entity-orginfo.vue'
import ActionPanel from './action-panel.vue'
import { dragscroll } from 'vue-dragscroll'
import fullscreen from '@/components/full-screen/component.vue'

import _ from 'lodash'
import { EntityType } from '@/libs/entitymodel/entity'
import EntityModelViewManger from '@/libs/entitymodel/entity-model-view'

interface Scroll{
  x: number;
  y: number;
}

/**
 * 实体显示视图
 */
@Component({
  name: 'entitiesView',
  components: {
    ToolPanel: ToolPanel,
    ActionPanel: ActionPanel,
    fullscreen: fullscreen,
    OrgInfoPanel: OrgInfoPanel

  },
  directives: { dragscroll }
})
export default class EntitiesView extends Vue {
  expended = true;
  /// 全屏状态
  fullscreen = false;

  /**
   * 当前视图对象
   */
  @Prop({
    type: Object,
    required: true
  })
  viewManger!: EntityModelViewManger

  /**
   * 当前是否只读
   */
  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  readOnly!: boolean

  /**
   * 界面放大系数
   */
  get scale (): number {
    return this.viewManger.scale
  }

  /**
   * 滚动位置
   */
  get scroll (): Scroll {
    return {
      x: this.viewManger.scrollX,
      y: this.viewManger.scrollY
    }
  }

  /**
   * 属性面版标题
   */
  @Prop({
    type: String,
    required: false,
    default: '属性'
  })
  propertyPanleTitle!: string

  // 构建窗口的大小
  clientX: number|string='100%';
  clientY: number|string='100%';

  // 构建窗口的原始大小
  rawClinetX=0;
  rawClientY=0;

  /// 计算当前构建面版的放大数
  get transform () {
    return `scale(${this.scale})`
  }

  /// 放大操作
  doZoomin () {
    // this.clientX = 100
    // this.clientY = 100
    this.$emit('zoom', this.scale + 0.1)
  }

  /// 缩小操作
  doZoomout () {
    // this.clientX = this.rawClinetX / this.curScale
    // this.clientY = this.rawClientY / this.curScale
    this.$emit('zoom', this.scale - 0.1)
  }

  /// 全屏切换
  private fullscreenChange (state: any) {
    this.fullscreen = state
    this.expended = !state
  }

  /**
   * 折叠变化
   */
  onExpended (expended: boolean) {
    this.expended = expended
  }

  // 处理全屏
  onFullScreen (fullscreen: boolean) {
    (this.$refs.fullscreen as any).toggle()
  }

  /// 添加新的实体事件
  onAddNewEntity (type: EntityType) {
    this.$emit('addNewEntity', type)
  }

  /// 添加联结实体
  addLinkEntity () {
    this.$emit('addLinkEntity')
  }

  onDelObject () {
    this.$emit('delObject')
  }

  onMoveObject () {
    this.$emit('moveObject')
  }

  /**
   * 加载页面
   */
  mounted () {
    this.$nextTick(() => {
      this.doScroll()
    })
  }

  /// 滚动到指定的位置
  doScroll () {
    (this.$refs.fullscreen as Vue).$el.scrollTo(this.scroll.x, this.scroll.y)
  }

  /// 完成滚动事件
  onDragScrollEnd () {
    this.$emit('scroll', (this.$refs.fullscreen as Vue).$el.scrollLeft, (this.$refs.fullscreen as Vue).$el.scrollTop)
  }

  /**
   * 面版空白点击
   */
  contextClick () {
    this.$emit('onContextClick')
  }

  /**
   * 保存数据
   */
  onSave (version?: string) {
    this.$emit('onSave', version)
  }
}
