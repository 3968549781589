/**
 * 选择的对象类型
 */
export type SelectObjectType='none'|'entity'|'view'|'field'

/**
 * 选择对象
 */
export interface SelectObject{
  type: SelectObjectType;
  title: string;
  object: any;
}
/**
 * 属性面版本列表
 */
const panels = {
  panelNone: () => import('./not-selected-panel.vue'),
  panleentity: () => import('./entity-property-panel.vue'),
  panlefield: () => import('./field-property-panel.vue')
}

/**
 * 属性面版对应关系
 */
export const panelsMap = {
  none: {
    title: '属性工具',
    component: 'panelNone'
  },
  entity: {
    title: '实体属性',
    component: 'panleentity'
  },
  field: {
    title: '字段属性',
    component: 'panlefield'
  }
}

export default panels
