
import { Prop, Vue, Component } from 'vue-property-decorator'
import propertyPanels, { SelectObject, panelsMap } from './property-panel/index'
import EntityModelManger from '@/libs/entitymodel'

// 属性面版，动态切换选择对象对应的属性组件
@Component({
  name: 'propertyPanel',
  components: propertyPanels
})
export default class PropertyPanel extends Vue {
  /**
   * 当前选择的对象
   */
  @Prop({
    type: Object,
    required: false,
    default: () => {
      return {
        type: 'none',
        object: undefined
      }
    }
  })
  selectObject!: SelectObject

  /**
   * 当前是否只读
   */
  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  readOnly!: boolean

  /**
   * 当前数据模型对象
   */
  @Prop({
    type: Object,
    required: true
  })
  entityModelManger!: EntityModelManger

  /// 面版对应表
  panels= panelsMap

  /**
   * 转发事件
   */
  onAddLeftjoin (join: any) {
    this.$emit('onAddLeftjoin', join)
  }

  /**
   * 删除leftjoin
   */
  onDelLeftjoin (join: any) {
    this.$emit('onDelLeftjoin', join)
  }

  /**
    * 修改leftjoin
  */
  onEditLeftjoin (join: any) {
    this.$emit('onEditLeftjoin', join)
  }

  /**
   * 实体的父关系变化时e
   */
  onEntityParentChange (uuid: string, parentUuid: string) {
    this.$emit('entityParentChange', uuid, parentUuid)
  }

  /// 移动实体
  entityMove (entityUuid: string, orgUuid: string, newOrgUuid: string) {
    this.$emit('entityMove', entityUuid, orgUuid, newOrgUuid)
  }
}
