import { showModal } from '@/libs/modal-helper'
import _ from 'lodash'
import modal from './edit-orgnode-modal.vue'
import TreeNode from './org-tree-node'

/**
 * 显示修改组织节点对话框
 * @param node
 * @param onOk
 */
export function showModiOrgNodeModal (node: TreeNode, onOk?: (data: TreeNode) => Promise<void>) {
  showModal<TreeNode>(modal, {
    props: {
      title: '修改组织节点',
      value: _.cloneDeep(node)
    }
  }, true, onOk, undefined)
}
/**
 * 显示新加组织节点对话框
 * @param parentNode
 * @param onOk
 */
export function showAddOrgNodeModal (parentNode: TreeNode|undefined, onOk?: (data: TreeNode) => Promise<void>) {
  showModal<TreeNode>(modal, {
    props: {
      title: '新增组织节点',
      value: {
        name: 'neworg',
        title: '新组织',
        id: -1,
        expended: true,
        children: [],
        parent: parentNode,
        contextmenu: true
      }
    }
  }, true, onOk, undefined)
}
