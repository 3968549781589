
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import OrgInfo from '@/libs/entitymodel/entity-orginfo'
import { getterProject, dispatchTryLockOrgId, getterProjectId, dispatchUnLockOrgId, getterLockOrgIds, getterEntityModelVersion, getterProjectMemberRight } from '@/store'
import { WebApiProjectInfo } from '@/api/project'
import TreeNode from './entity-orginfo/org-tree-node'
import { showModiOrgNodeModal, showAddOrgNodeModal } from './entity-orginfo/edit-orgnode-modal'
import entityModelApi from '@/api/webapi-entity-model/webapi-entity-model-api'
import { getShortUuid } from '@/libs/uuid'
import EntityModelManger from '@/libs/entitymodel'
import { expect } from 'chai'

/// 空的组织id
const NullOrgId = ''

/**
 * 实体组织信息
 */
@Component({
  name: 'entityOrgInfo'
})
export default class EntityOrgInfo extends Vue {
  /**
   * 组织信息
   */
  @Prop({
    type: Object,
    required: false,
    default: () => {
      return new Map()
    }
  })
  orgs!: Record<string, OrgInfo>;

  /// 菜单功能是否禁用
  get disable () {
    const rights = getterProjectMemberRight()
    if (!rights) return true
    return !rights.updateDataModel
  }

  /**
   * 实体管理器
   */
  @Prop({
    type: Object,
    required: true
  })
  entityModelManger!: EntityModelManger

  @Prop({
    type: String,
    required: false
  })
  selectOrgUuid?: string

  /// 当前上下文菜单的当前组织id
  curNode: TreeNode|null=null;

  /**
   * 组织数据
   */
  treeData: TreeNode[] = [];

  /// 剪切节点
  cutNode?: TreeNode;

  inPaste = false;

  addNodeName = '';
  addNodeTitle = '';

  /**
   * 创建时初始化数据
   */
  created () {
    this.initTreeData()
  }

  /**
   * 绘制树的节点
   */
  renderContent (h: any, { data }: any) {
    const context = [
      h('span', data.title)
    ]
    if (data.locked) {
      context.push(h('Icon', {
        props: {
          custom: 'iconfont iconlock'
        },
        style: {
          marginLeft: '8px'
        }
      }))
    }

    return h('span', {
      style: {
        display: 'inline-block',
        width: '100%'
      }
    }, [
      h('span', context)
    ])
  }

  /**
   * 取得树数据
   */
  @Watch('orgs')
  initTreeData () {
    const projecInfo = getterProject() as WebApiProjectInfo
    const res: TreeNode[] = []
    for (const key in this.orgs) {
      const item = this.orgs[key]
      if (item.parentOrg === undefined) {
        res.push(this.toTreeData(item))
      }
    }

    if (res.length === 0) {
      res.push({
        title: projecInfo ? projecInfo.org : '当前没有项目信息',
        name: projecInfo ? projecInfo.org : 'unname',
        id: NullOrgId,
        expand: true,
        children: [],
        contextmenu: true,
        locked: false
      })
    }

    this.treeData = res
  }

  /**
   * 将组织信息转为树节点
   */
  private toTreeData (org: OrgInfo): TreeNode {
    const re = {
      title: org.title,
      name: org.name,
      id: org.uuid,
      expand: true,
      children: org.children.map((ch) => this.toTreeData(this.orgs[ch.uuid])),
      contextmenu: true,
      locked: (this.lockedOrgIds.findIndex((item) => item === org.uuid) >= 0),
      selected: org.uuid === this.selectOrgUuid
    }

    re.children.forEach((item) => {
      item.parent = re
    })
    return re
  }

  /**
   * 选择节点事件
   */
  onSelect (items: any, item: TreeNode) {
    this.$emit('orgChanged', item.id)
  }

  /**
   * 当前上下文菜单弹出
   */
  handleContextMenu (data: any) {
    this.curNode = data
  }

  /// 处理修改节点名称
  handleContextMenuRename () {
    if (this.disable) return
    const that = this
    showModiOrgNodeModal(this.curNode!, (data) => {
      return new Promise<void>((resolve, reject) => {
        entityModelApi.modiOrgNode(this.entityModelManger.projectId, this.entityModelManger.version, this.curNode!.id,
          {
            uuid: this.curNode!.id,
            name: data.name,
            title: data.title,
            children: []
          }
        )
          .then(() => {
            this.$emit('onOrgDataReload')
            resolve()
          }).catch((err: Error) => reject(err))
      })
    })
  }

  /**
   * 处理删除操作
   */
  handleContextMenuDelete () {
    if (this.disable) return
    if (this.treeData.length === 1 && this.treeData[0].children.length === 0) {
      this.$Message.warning('最后一个节点不允许删除')
      return
    }
    if (this.curNode) {
      const parentCollection = this.curNode.parent
        ? this.curNode.parent.children
        : this.treeData
      const index = parentCollection.findIndex(
        (item) => item.id === this.curNode!.id
      )

      this.$Modal.confirm({
        title: '确认',
        content: `确定要删除节点(${this.curNode.title})吗?`,
        onOk: () => {
          entityModelApi.removeOrgNode(
            getterProjectId()!, getterEntityModelVersion()!.uuid, this.curNode!.id)
            .then((resopse) => {
              this.$emit('onOrgDataReload')
            }).catch((err: Error) => {
              this.$Notice.error({
                title: '错误',
                desc: '删除组织节点失败' + err.message
              })
            })
        }
      })
    }
  }

  /**
   * 处理新增操作
   */
  handleContextMenuAdd () {
    if (this.disable) return
    showAddOrgNodeModal(this.curNode!, (data) => {
      return new Promise<void>((resolve, reject) => {
        data.id = getShortUuid()
        entityModelApi.addOrgNode(
          getterProjectId()!,
          getterEntityModelVersion()!.uuid,
          this.curNode!.id,
          {
            uuid: data.id,
            name: data.name,
            title: data.title,
            children: []
          }
        ).then((orgInfoData) => {
          this.$emit('onOrgDataReload')
          resolve()
        }).catch((err: Error) => {
          reject(err)
        })
      })
    })
  }

  /**
   * 剪切节点
   */
  handleContextMenuCut () {
    if (this.disable) return
    this.cutNode = this.curNode!
    this.$set(this, 'cutNode', this.curNode)
    this.inPaste = true
  }

  /**
   * 在指定位置粘帖
   */
  handleContextMenuPasteAt (isAddToChildren: boolean, isBefore?: boolean) {
    if (this.disable) return
    if (!this.curNode) {
      this.$Notice.warning({
        title: '提醒',
        desc: '请选择一个节点，进行粘帖!'
      })
      return
    }
    if (this.checkCurNodeIsChildCutNode(this.cutNode!, this.curNode!)) {
      this.$Notice.warning({
        title: '提醒',
        desc: '不能粘帖到子自己或子节点!'
      })
      this.cutNode = undefined
      this.inPaste = false
      return
    }
    let index = -1
    if (!isAddToChildren) {
      // 取得当选择节点在父亲节点的位置index
      index = this.curNode.parent!.children.findIndex((item) => item.id === this.curNode!.id)
      if (!isBefore) index++
    }
    // 执行称动动作
    entityModelApi.moveOrgNode(
      this.entityModelManger.projectId, this.entityModelManger.version,
      this.cutNode!.id,
      isAddToChildren ? this.curNode.id : this.curNode.parent!.id,
      index
    ).then((orgInfoData) => {
      this.$emit('onOrgDataReload')
      this.cutNode = undefined
      this.inPaste = false
    }).catch((err: Error) => {
      this.$Notice.error({
        title: '错误',
        desc: '操作失败:' + err.message
      })
    })
  }

  /**
   * 检查选择节点是不是剪切节点的子节点
   */
  private checkCurNodeIsChildCutNode (
    cutNode: TreeNode,
    node: TreeNode
  ): boolean {
    if (node.id === cutNode.id) return true
    let pNode: TreeNode | undefined = node
    while (true) {
      if (!pNode) return false
      if (pNode.id === cutNode.id) return true
      pNode = pNode.parent
    }
  }

  /**
   * 取得当前工程的锁定组织
   */
  get lockedOrgIds () {
    return getterLockOrgIds()
  }

  /**
   * 锁定当前节点
   */
  handleOrgLock () {
    if (this.disable) return
    const orgName = this.curNode?.title

    // 在锁定之前，先从服务器先加载这个组织的数据
    const orgUuid = this.curNode!.id
    expect(orgUuid, '没有找到节点的uuid')

    this.$Loading.start()

    this.entityModelManger.loadDataByOrgIdAndRation(orgUuid, true)
      .then(() => {
        return dispatchTryLockOrgId(getterProjectId()!, getterEntityModelVersion()!.uuid, orgUuid)
      }).then((response) => {
        this.curNode!.locked = true
        this.$Notice.success({
          title: '成功',
          desc: `锁定(${orgName})成功！您可以修改数据模型了！`
        })
        this.$emit('orgLockChange', this.curNode?.id, true)
      })
      .catch((err: Error) => {
        this.$Notice.error({
          title: '错误',
          desc: `锁定名为(${orgName})失败！` + err.message
        })
      }).finally(() => {
        this.$Loading.finish()
      })
  }

  /**
   * 解锁
   */
  handleOrgUnLock () {
    if (this.disable) return
    const orgName = this.curNode?.title

    const orgUuid = this.curNode!.id
    expect(orgUuid, '没有找到节点的uuid')

    this.$Loading.start()

    // 解锁前先保存
    this.entityModelManger.saveOrgModelData(orgUuid)
      .then(() => {
        return dispatchUnLockOrgId(getterProjectId()!, getterEntityModelVersion()!.uuid, this.curNode!.id)
      }).then((response) => {
        this.curNode!.locked = false
        this.$Notice.success({
          title: '成功',
          desc: `解锁(${orgName})成功！`
        })
        this.$emit('orgLockChange', this.curNode?.id, false)
      })
      .catch((err: Error) => {
        this.$Notice.error({
          title: '错误',
          desc: `解锁名为(${orgName})失败！` + err.message
        })
      }).finally(() => {
        this.$Loading.finish()
      })
  }
}
