
import { Prop, Vue, Component, Watch, Provide } from 'vue-property-decorator'
import EntitiesView from './components/entities-view.vue'
import EntityOrgInfo from './components/entity-orginfo.vue'
import EntityModelManger from '@/libs/entitymodel'
import { dispatchUpdateProject, getterProjectId, dispatchUpdateActiveEntityUuid, dispatchUpdateLockOrgIds, getterLockOrgIds, dispatchCurEntityModelVersion, getterUserInfo, getterEntityModelVersion } from '@/store'
import { WebApiProjectInfo } from '@/api/project'
import PropertyPanel from './components/property-panel.vue'
import { expect } from 'chai'
import { SelectObject, panelsMap } from './components/property-panel/index'
import EntityLeftJoin from '@/libs/entitymodel/entity-leftjoin'
import Entity, { EntityType } from '@/libs/entitymodel/entity'
import EntitiesViewUi from './entities-view-ui'
import EntityModelViewManger from '@/libs/entitymodel/entity-model-view'
import { getShortUuid } from '@/libs/uuid'
import showSelectLinkEntityModal from './components/action-panel/add-linkentity-modal'
import { reject } from 'lodash'
import showSelectOrgTreeModal from '@/components/modal/org-selector-modal'
import DelCheck from '@/libs/entitymodel/entity-delcheck'

@Component({
  name: 'builderSystem',
  components: { EntitiesView, EntityOrgInfo, PropertyPanel }
})
export default class BuilderSystem extends Vue {
  /// 当前的工程信息
  project?: WebApiProjectInfo

  // ui对象
  ui!: EntitiesViewUi;

  // 实体模型对象
  entityModelManger: EntityModelManger=new EntityModelManger()

  // 当前视图管理器
  viewManger: EntityModelViewManger=new EntityModelViewManger({
    entityPos: {},
    orgId: '',
    scale: 1,
    scrollX: 0,
    scrollY: 0
  })

  /// 当前的组织
  curOrgId=''
  /// 当前的组织是否锁定
  orgIsLock=false

  /// 模型数据是否已经修改
  modelModified=false

  /**
   * 取得当前工程的锁定组织
   */
  get lockedOrgIds () {
    return getterLockOrgIds()
  }

  /**
   * 处理创建事件，在事件中取得当前项目的实体配置数据
   */
  created () {
    this.$Loading.start()

    const projectId = getterProjectId()
    if (!projectId) {
      this.$Notice.error({
        title: '错误',
        desc: '当前没有设置工程的id'
      })
      return
    }

    /// 更新当前项目的基本信息
    dispatchUpdateProject(projectId)
      .then((data) => {
        this.project = data as WebApiProjectInfo

        // 更新当前版本
        return dispatchCurEntityModelVersion()
      })
      .then((entityVersion) => {
        this.entityModelManger.loadAllOrgsAndEntities(projectId, entityVersion.uuid)

        /// 加载模型数据
        return this.doOrgEntityDataReload(projectId, entityVersion.uuid)
      })
      .then(() => {
        this.$Message.success({
          content: '实体模型加载成功!'
        })
      })
      .catch((ex: Error) => {
        this.$Notice.error({
          title: '错误',
          desc: '模型数据加载错误:' + ex.message
        })
      }).finally(() => {
        this.$Loading.finish()
      })
  }

  /// 当用户用户选择的组织变更
  onOrgChanged (orgId: string) {
    // 加载对应的组织的实体数据
    expect(orgId, '参数orgId不允许为空').not.undefined.and.not.empty

    this.$Loading.start()
    this.doOrgEntityDataReload(this.entityModelManger.projectId, this.entityModelManger.version, orgId)
      .then(() => {
        this.$Message.success('加载组织实体模型数据成功')
      })
      .catch((err: Error) => {
        this.$Notice.error({
          title: '错误',
          desc: '加载组织实体模型数据失败:' + err.message
        })
      })
      .finally(() => this.$Loading.finish())
  }

  /**
   * 当前组织变更 从view中取得对应实体信息
   */
  private doOrgChanged (orgId: string) {
    expect(orgId, '参数orgId不允许为空').not.undefined.and.not.empty

    if (this.ui) this.ui.clear()

    const that = this

    this.$nextTick(() => {
      this.ui = new EntitiesViewUi('entities-view-body', this.entityModelManger, orgId, this)
      this.viewManger = this.ui.viewMangerInstance
      /// 加载panel对象
      this.ui.drawCurView(
        {
          onEntityActive: (entity: Entity) => {
            that.activeEntity(entity)
          },
          onFieldActive: (data: any) => {
            that.activeField(data)
          }
        }
      )
      that.modelModified = false
    })

    /// 检查当前是否已经锁住模型
    this.orgIsLock = this.lockedOrgIds.findIndex((item) => item === orgId) >= 0
  }

  /**
   * 检查组织锁变化事件
   */
  onOrgLockChange (orgUuid: string, lock: boolean) {
    this.orgIsLock = lock
    // 更新当前界面数据
    this.doOrgChanged(orgUuid)
  }

  /**
   * 通过uuid取得实体对象
   */
  getEntityByUuid (uuid: string) {
    return this.entityModelManger.entitiesMap[uuid]
  }

  /**
   * 当前的选择对象
   */
  selectObject: SelectObject={
    type: 'none',
    title: panelsMap.none.title,
    object: undefined
  }

  /**
   * 选择中一个字段
   */
  activeField (data: any) {
    this.selectObject = {
      type: 'field',
      title: panelsMap.field.title,
      object: data
    }
    dispatchUpdateActiveEntityUuid(data.entity.uuid)
  }

  /// 视图的放大缩小事件
  onViewZoom (scale: number) {
    this.viewManger.scale = scale
  }

  /// 视图的滚动事件
  onViewScroll (x: number, y: number) {
    this.viewManger.scrollX = x
    this.viewManger.scrollY = y
  }

  /**
   * 激活实体对象
   */
  activeEntity (entity: Entity) {
    this.selectObject = entity ? {
      type: 'entity',
      title: panelsMap.entity.title,
      object: entity
    } : {
      type: 'none',
      title: panelsMap.none.title,
      object: undefined
    }
    dispatchUpdateActiveEntityUuid(entity?.uuid)
  }

  /// 添加了新的左联结
  onAddLeftjoin (join: EntityLeftJoin) {
    if (!join.uuid) join.uuid = getShortUuid()
    this.entityModelManger.addEntityLeftJoin(this.selectObject?.object?.uuid, join)
    this.ui.drawEntityLeftJoin(join.mainEnityUuid, join.uuid)
  }

  /// 删除一个左联结
  onDelLeftjoin (join: EntityLeftJoin) {
    this.ui.eraseEntityLeftJoin(this.selectObject?.object?.uuid, join.uuid)
    this.entityModelManger.removeEntityLeftJoin(join)
  }

  /// 修改左联结
  onEditLeftjoin (join: EntityLeftJoin) {
    this.onDelLeftjoin(join)
    this.onAddLeftjoin(join)
  }

  /// 添加新的实体
  onAddNewEntity (type: EntityType) {
    const that = this
    const entity = this.entityModelManger.addNewEntity(this.ui.getOrgId(), undefined, type)
    this.ui.drawEntity(entity, {
      onEntityActive: (entity: Entity) => {
        that.activeEntity(entity)
      },
      onFieldActive: (data: any) => {
        that.activeField(data)
      }
    }, this.ui.getOrgId(), true)
  }

  /**
   * 添加一个新联结实体
   */
  onAddLinkEntity () {
    const that = this
    showSelectLinkEntityModal(this.entityModelManger, this.ui.getOrgId(), (entityUuid) => {
      return new Promise<void>((resolve, reject) => {
        that.viewManger.getOrCreateEntityPos(entityUuid, this.entityModelManger.getEntityByUuid(entityUuid)!.orgId)
        this.ui.drawEntity(that.entityModelManger.getEntityByUuid(entityUuid)!, {
          onEntityActive: (entity: Entity) => {
            that.activeEntity(entity)
          },
          onFieldActive: (data: any) => {
            that.activeField(data)
          }
        }, this.ui.getOrgId(), true)
        resolve()
      })
    })
  }

  /// 删除对象
  onDelObject () {
    if (!this.selectObject) {
      this.$Notice.warning({
        title: '错误',
        desc: '请选择要删除的对象'
      })
      return
    }
    // 删除实体
    if (this.selectObject.type === 'entity') {
      this.$Modal.confirm({
        title: '确认',
        content: '确认要删除实体吗?',
        onOk: () => {
          this.ui.eraseEntity(this.selectObject.object.uuid)
        }
      })
    }

    if (this.selectObject.type === 'field') {
      const entity = this.selectObject.object.entity as Entity
      const fieldUuid = this.selectObject.object.field.uuid
      // 取得字段在实体中的次序
      const index = entity.fields.findIndex((field) => field.uuid === fieldUuid)
      this.$Modal.confirm({
        title: '确认',
        content: '确认要删除字段吗?',
        onOk: () => {
          entity.fields.splice(index, 1)
        }
      })
    }
  }

  // 移动对象
  onMoveObject () {
    if (!this.selectObject) {
      this.$Notice.warning({
        title: '错误',
        desc: '请选择要移动的对象'
      })
      return
    }
    // 移动实体
    if (this.selectObject.type === 'entity') {
      showSelectOrgTreeModal().then(orgUuid => {
        console.log(orgUuid)
      })
    }
  }

  /// 实体的父实体变化事件
  onEntityParentChange (uuid: string, baseEntityUuid: string) {
    this.ui.eraseEntityExtends(uuid)
    const entity = this.entityModelManger.getEntityByUuid(uuid)
    entity!.baseEntityUuid = baseEntityUuid
    this.ui.drawEntityExtends(uuid)
  }

  /// 移动实体所在的组织
  onEntityMove (entityUuid: string, orgUuid: string, newOrgUuid: string) {
    const entity = this.entityModelManger.getEntityByUuid(entityUuid)

    // 从原来视图中移出实体
    entity!.orgId = newOrgUuid
    this.ui.eraseEntity(entityUuid)
    // 在新的视图中添加这个这实体
    const view = this.entityModelManger.getOrCreateOrgViewManger(newOrgUuid)
    view.entityPos[entity!.uuid] = {
      uuid: entity!.uuid,
      left: 100,
      top: 100,
      orgUuid: newOrgUuid,
      expended: true
    }
  }

  /**
   * 保存数据到指定的版本，如果version为空，则表示当前版本
   */
  onSave (version?: string) {
    this.$Loading.start()

    this.entityModelManger.saveAll(version)
      .then(() => {
        this.$Notice.success({
          title: '成功',
          desc: '保存实体模型数据成功'
        })
      })
      .catch((err: Error) => {
        this.$Notice.error({
          title: '错误',
          desc: '保存实体模型数据出错:' + err.message
        })
      }).finally(() => {
        this.$Loading.finish()
      })
  }

  /**
   * 加载组织数据
   */
  onOrgDataReload () {
    this.$Loading.start()
    this.doOrgEntityDataReload(this.entityModelManger.projectId, this.entityModelManger.version)
      .then(() => {
        this.$Message.success({
          content: '数据模型重新加载成功'
        })
      }).finally(() => {
        this.$Loading.finish()
      })
  }

  /// 加载组织数据
  doOrgEntityDataReload (projectId: number, version: string, orgUuid?: string) {
    const that = this
    that.curOrgId = orgUuid || ''
    return new Promise<void>((resolve, reject) => {
      // 更新当前锁定的组织信息
      let lockedOrgIds: string[] = []
      return dispatchUpdateLockOrgIds(projectId, version)
        .then((lockedOrg) => {
          lockedOrgIds = lockedOrg
          return that.entityModelManger.loadOrgData(projectId, version)
        })
        .then(() => {
        // 加载当前锁定组织ids
          return lockedOrgIds
        })
        .then((lockedOrgIds) => {
        // 返回锁定的第一个组织
          if (orgUuid) return orgUuid

          if (lockedOrgIds && lockedOrgIds.length > 0) {
            return lockedOrgIds[0]
          } else {
          // 否则返回第一个组织
            for (const orgId in that.entityModelManger.orgs) {
              return orgId
            }
          }
        })
        .then((curOrgUuid) => {
          // 加载该组织的数据
          return new Promise<void>((resolve, reject) => {
            that.entityModelManger.loadDataByOrgIdAndRation(curOrgUuid!)
              .then(() => {
                that.doOrgChanged(curOrgUuid!)
                resolve()
              })
              .catch((err: Error) => reject(err))
          })
        })
        .then(() => resolve())
        .catch((err: Error) => reject(err))
    })
  }

  /**
   * 监视锁定列表变化
   */
  @Watch('lockedOrgIds')
  WatchLockedOrgIds (value: string[]) {
    /// 检查当前是否已经锁住模型
    this.orgIsLock = value.findIndex((item) => item === this.curOrgId) >= 0
  }

  @Watch('entityModelManger', {
    deep: true
  })
  watchModel () {
    this.modelModified = true
  }
}
