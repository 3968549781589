import { Vue } from 'vue-property-decorator'
import { expect } from 'chai'
import { jsPlumb } from 'jsplumb'
import { getShortUuid } from '../uuid'

/**
 * jsplumbhelper绘制处理工具
 */

interface Line{
    // 线条
    Line: {
      connect: any[];
      style: any;
    };
    // 锚点
    Anchor: any[];
  }

/**
   * 联线的风格
   */
const connections: Record<string, Line> = {
  /// 从另一个实体派生
  extendsFrom: {
    Line: {
      connect: [['Arrow', {
        width: 10,
        length: 8,
        location: 1,
        paintStyle: {
          strokeWidth: 5,
          stroke: 'gray'
        }
      }]],
      style: {
        paintStyle: {
          stroke: 'gray', strokeWidth: 3
          //, outlineStroke: 'white', outlineWidth: 5
        }
      }
    },
    Anchor: ['Top', 'Bottom']
  },
  /// 左联结到实体
  leftJoinTo: {
    Line: {
      connect: [['Arrow', { width: 10, length: 8, location: 1 }]],
      style: {
        paintStyle: { stroke: 'black', strokeWidth: 0.5 }
      }
    },
    Anchor: ['Left', 'Right']
  },
  /// 关联字段
  transientFrom: {
    Line: {
      connect: [['Arrow', { width: 10, length: 8, location: 1 }]],
      style: {
        paintStyle: { stroke: 'gray', strokeWidth: 0.5 }
      }
    },
    Anchor: ['Left', 'Right']
  }

}
/**
 * JsPlumb对象
 */
interface JsPlumbObject {
    // 对象uuid
    uuid: string;
    object: any;
}

/**
 * 联结对象
 */
interface LinkObject{
    // 起点
    from: string;
    // 终点
    to: string;
    // 联结对象
    link: JsPlumbObject;
}

export default class JsPlubmHelper {
    vm: Vue

    // 组对象
    groupObjects: Map<string, JsPlumbObject>=new Map()
    // 联结对象
    links: LinkObject[]=[]

    /// jsPlumb工具
    private jplumb=jsPlumb.getInstance({
      Container: 'context',
      PaintStyle: {
        strokeWidth: 1,
        stroke: 'black'
      },
      Connector: ['Bezier', { curviness: 30 }], // Bezier Flowchart
      Endpoint: ['Dot', { radius: 1 }],
      EndpointStyle: { fill: '#567567' }
    });

    /// 联结对象

    /**
     * 构造实体模型ui对象
     * @param orgId
     * @param entityModelManger
     * @param vm
     */
    constructor (vm: Vue) {
      expect(vm, '参数vm不允许为空').not.undefined
      this.vm = vm
    }

    /**
     * 创建JsPlumbObject对象
     * @param jsObject 可以是联结也可以是联结组对象
     */
    private createJsPlumbObject (jsObject: any): JsPlumbObject {
      expect(jsObject, '参数jsObject不允许为空').not.undefined

      return {
        uuid: getShortUuid(),
        object: jsObject
      }
    }

    /**
     * 添加联结对象
     * @param from 起启对象uuid
     * @param to 目标对象uuid
     * @param jsLink 联结对象
     */
    private pushJsLink (from: string, to: string, jsLink: any) {
      try {
        expect(from, '参数from不允许为空').not.undefined
        expect(to, '参数to不允许为空').not.undefined
        expect(jsLink, '参数jsLink不允许为空').not.undefined

        const ob = this.createJsPlumbObject(jsLink)
        this.links.push({
          from: from,
          to: to,
          link: ob
        })
      } catch (err) {
        console.warn(err)
      }
    }

    /**
     * 创建一个实体的组对象
     * @param uuid 实体对象uuid
     * @param collapsed 折叠展开状态
     */
    createEntiyGroup (uuid: string, collapsed: boolean, drapStop: (pos: number[]) => void) {
      expect(uuid, '参数uuid不允许为空').not.undefined.and.not.empty
      const el = document.getElementById(uuid)

      expect(el, `没有找到uuid=${uuid}的html对象`).not.undefined

      this.stopDraw()
      /// 这里使用uuid做为组的id
      const group = this.jplumb.addGroup({
        id: uuid,
        el: el!,
        anchor: ['Top',
          'Bottom'],
        draggable: true,
        collapsed: collapsed,
        dragOptions: {
          stop: (params) => {
            if (drapStop) {
              drapStop(params.pos)
            }
          }
        }
      })
      this.refresh()

      this.groupObjects.set(uuid, this.createJsPlumbObject(group))
    }

    /**
     * 删除实体组
     * @param uuid
     */
    removeEntityGroup (uuid: string) {
      expect(uuid, '参数uuid不允许为空').not.undefined.and.not.empty

      const group = this.groupObjects.get(uuid)

      if (group) {
        const member: string|undefined = undefined
        this.stopDraw()
        this.jplumb.removeGroup(group.object, member!, false)
        this.refresh()
      }
      // 从列表中移除这个组
      this.groupObjects.delete(uuid)
    }

    /**
     * 删除实体左联结
     * @param fromUuid
     * @param toUuid
     */
    removeEntityLeftJoin (fromUuid: string, toUuid: string) {
      const index = this.links.findIndex((item) => item.from === fromUuid && item.to === toUuid)
      if (index < 0) return
      const join = this.links[index].link.object
      this.jplumb.deleteConnection(join as any)
      this.links.splice(index, 1)
    }

    /**
     * 关闭组
     * @param uuid
     */
    collapseEntityGroup (uuid: string) {
      const group = this.groupObjects.get(uuid)
      expect(group, `无法找到uuid=${uuid}的组对象`).not.undefined

      if (group) {
        this.vm.$nextTick(() => {
          this.stopDraw()
          this.jplumb.collapseGroup(uuid)
          this.refresh()
        })
      }
    }

    /**
     * 展开组
     * @param uuid
     */
    expandEntityGroup (uuid: string) {
      const group = this.groupObjects.get(uuid)
      expect(group, `无法找到uuid=${uuid}的组对象`).not.undefined

      if (group) {
        this.vm.$nextTick(() => {
          this.stopDraw()
          this.jplumb.expandGroup(uuid)
          this.refresh()
        })
      }
    }

    /**
     * 创建实体的派生联结
     * @param fromUuid
     * @param toUuid
     */
    createEntityExtendLink (fromUuid: string, toUuid: string) {
      const line = connections.extendsFrom

      this.stopDraw()
      const conn = this.jplumb.connect({
        source: fromUuid,
        target: toUuid,
        overlays: line.Line.connect,
        anchor: line.Anchor
      }, line.Line.style)
      this.refresh()

      // 添加link对象
      this.pushJsLink(fromUuid, toUuid, conn)
    }

    /**
     * 删除实体的派生联结
     * @param fromUuid
     * @param toUuid
     * @returns
     */
    removeEntityExtendLink (fromUuid: string, toUuid: string) {
      const index = this.links.findIndex((item) => item.from === fromUuid && item.to === toUuid)
      if (index < 0) return
      const join = this.links[index].link.object
      this.jplumb.deleteConnection(join as any)
      this.links.splice(index, 1)
    }

    /**
     * 删除到实体的派生联结
     * @param toUuid
     */
    removeToEntityExtendLink (toUuid: string) {
      for (let i = this.links.length - 1; i >= 0; i--) {
        const link = this.links[i]
        if (link.to === toUuid) {
          this.jplumb.deleteConnection(link.link.object as any)
          this.links.splice(i, 1)
        }
      }
    }

    /// 创建实体的左联结
    createEntityLeftJoin (fromUuid: string, toUuid: string) {
      if (this.checkLinkIsExist(fromUuid, toUuid)) return

      const line = connections.leftJoinTo
      const conn = this.jplumb.connect({
        source: fromUuid,
        target: toUuid,
        overlays: line.Line.connect,
        anchor: line.Anchor
      }, line.Line.style)

      this.refresh()
      // 添加link对象
      this.pushJsLink(fromUuid, toUuid, conn)
    }

    /// 创建字段联结
    createFieldJoinLink (fromUuid: string, toUuid: string, isLeftJion = false) {
      const line = isLeftJion ? connections.leftJoinTo : connections.transientFrom

      if (this.checkLinkIsExist(fromUuid, toUuid)) return

      this.stopDraw()
      const conn = this.jplumb.connect({
        source: fromUuid,
        target: toUuid,
        overlays: line.Line.connect,
        anchor: line.Anchor
      }, line.Line.style)

      this.refresh()

      // 添加link对象
      this.pushJsLink(fromUuid, toUuid, conn)
    }

    /**
     * 检查联结对象是否已经存在
     * @param fromUuid
     * @param toUuid
     */
    checkLinkIsExist (fromUuid: string, toUuid: string): boolean {
      const index = this.links.findIndex((item) => item.from === fromUuid && item.to === toUuid)
      return index >= 0
    }

    /// 停止绘制
    stopDraw () {
      this.jplumb.setSuspendDrawing(true)
    }

    /// 让整个jsplumb重绘一下
    refresh () {
      this.jplumb.setSuspendDrawing(false, true)
    }

    /**
     * 清除jsplumb所有的数据
     */
    clear () {
      this.jplumb.reset()
      this.groupObjects.clear()
      this.links.splice(0, this.links.length)
    }

    /// 取得底层的jplumb对象
    get jplumbInstance () {
      return this.jplumb
    }
}
