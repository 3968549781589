import EntityModelManger from '@/libs/entitymodel'
import Entity from '@/libs/entitymodel/entity'
import EntityModelViewManger, { EntityPosition } from '@/libs/entitymodel/entity-model-view'
import JsPlubmHelper from '@/libs/entitymodel/jsplumb-helper'
import { expect } from 'chai'
import { Vue } from 'vue-property-decorator'
import EntityPanel from './components/entity-panel.vue'
import entityModelApi from '@/api/webapi-entity-model/webapi-entity-model-api'

/**
 * 实体构建面版ui操作工具
 */
export default class EntitiesViewUi {
    private jsplumbHelper: JsPlubmHelper
    // 显示对象的节点
    private root: HTMLElement
    // 当前组织id
    private curOrgId=''

    /// 数据模型对象
    private modelManger: EntityModelManger

    /// 当前视图数据
    private viewManger: EntityModelViewManger
    /// 当前vue对象
    private vm: Vue

    /**
     * 构造方法
     * @param vm
     */
    constructor (rootId: string, modelManger: EntityModelManger, initOrgId: string, vm: Vue) {
      expect(vm, '参数vm不允许为空').not.undefined
      expect(rootId, '参数rootId不允放为空').not.undefined.and.not.empty
      expect(modelManger, '参数modelManger不允许为空').not.undefined
      expect(initOrgId, '参数initOrgId不允许为空').not.undefined

      this.vm = vm

      const root = document.getElementById(rootId)
      expect(root, '参数rootId对应的html节点不存在').not.undefined.and.not.null

      this.curOrgId = initOrgId

      this.jsplumbHelper = new JsPlubmHelper(vm)
      this.root = root!
      this.modelManger = modelManger
      this.viewManger = modelManger.getOrCreateOrgViewManger(initOrgId)
    }

    /**
     * 描绘一个实体对象
     * @param entity 实体对象
     * @param on 事件监听配置
     * @param active 当前是否已经激活
     */
    drawEntity (entity: Entity, on: any, viewOrgId: string, active = false): Vue {
      expect(parent, '参安徽parent不可以为空').not.undefined
      expect(entity, '参数entity不允许为空').not.undefined
      expect(viewOrgId, '参数viewOrgId不允许为空').not.undefined

      const that = this
      const pos = this.viewManger.getOrCreateEntityPos(entity.uuid, viewOrgId)

      const vm = new Vue({
        components: {
          EntityPanel
        },
        render (h, cxt) {
          return h('EntityPanel', {
            props: {
              entity: entity,
              pos: pos,
              link: entity.orgId !== that.curOrgId,
              active: active,
              expended: pos.expended,
              viewOrgId: viewOrgId,
              manager: that.modelManger
            },
            attrs: {
              id: entity.uuid
            },
            on: {
              tagge: (uuid: string, expended: boolean) => {
                pos.expended = expended
                if (expended) {
                  that.jsplumbHelper.expandEntityGroup(uuid)
                } else {
                  that.jsplumbHelper.collapseEntityGroup(uuid)
                }
              },
              ...on
            }
          })
        }
      }).$mount()

      this.root.appendChild(vm.$el)

      // 创建对应的组对象
      this.jsplumbHelper.createEntiyGroup(entity.uuid, !!pos.expended, (newPos) => {
        pos.left = newPos[0]
        pos.top = newPos[1]
      })

      // 检查当前视图是否有到这个实体的派生，如果有，则绘制它
      for (const key in this.viewManger.entityPos) {
        const pos = this.viewManger.entityPos[key]
        const vwEntity = this.modelManger.getEntityByUuid(pos.uuid)
        if (vwEntity && vwEntity.baseEntityUuid === entity.uuid) {
          this.drawEntityExtends(vwEntity.uuid)
        }
      }
      return vm
    }

    /**
     * 抹除实体面版
     * @param uuid
     */
    eraseEntity (uuid: string) {
      this.jsplumbHelper.removeEntityGroup(uuid)

      // 从实图中删除
      this.viewManger.removeEntityPos(uuid)

      // 如果实体当前不是快捷状态，则从实体列表中删除
      const entity = this.modelManger.getEntityByUuid(uuid)
      if (entity && entity.orgId === this.curOrgId) {
        this.modelManger.removeEntity(uuid)
      }
    }

    /**
     * 删除实体左联结
     * @param entityUuid
     * @param uuidJoin
     */
    eraseEntityLeftJoin (entityUuid: string, uuidJoin: string) {
      // 取得实体的基础实体
      const entity = this.modelManger.getEntityByUuid(entityUuid)
      if (!entity) return
      if (!entity.joins || entity?.joins.length === 0) return // 没有联结信息
      const joinIndex = entity.joins.findIndex((item) => item.uuid === uuidJoin)
      if (!joinIndex || joinIndex < 0) return

      const join = entity.joins[joinIndex]

      this.jsplumbHelper.removeEntityLeftJoin(join.mainEnityUuid, join.joinEntityUuid)
      entity.joins.splice(joinIndex, 1)
    }

    /**
     * 创建实体的派生
     * @param uuid 实体uuid
     */
    drawEntityExtends (uuid: string) {
      // 取得实体的基础实体
      const entity = this.modelManger.getEntityByUuid(uuid)
      const baseEntityUuid = entity?.baseEntityUuid
      if (!baseEntityUuid) return // 如果没有基础实体，跳过
      const pos = this.viewManger.getEntityPos(baseEntityUuid)
      if (!pos) return // 当前视图没有这个实体，跳过

      if (this.jsplumbHelper.checkLinkIsExist(uuid, baseEntityUuid)) return

      this.jsplumbHelper.createEntityExtendLink(uuid, baseEntityUuid)

      // 检查视图中联接到这个实体的所有实体
      for (const pos of this.getFromExtendEntityPos(uuid)) {
        this.jsplumbHelper.createEntityExtendLink(pos.uuid, uuid)
      }
    }

    /**
     * 删除实体的派生关系
     * @param uuid
     */
    eraseEntityExtends (uuid: string) {
      // 取得实体的基础实体
      const entity = this.modelManger.getEntityByUuid(uuid)
      const baseEntityUuid = entity?.baseEntityUuid
      if (!baseEntityUuid) return // 如果没有基础实体，跳过
      this.jsplumbHelper.removeEntityExtendLink(uuid, baseEntityUuid)
      this.jsplumbHelper.removeToEntityExtendLink(uuid)
    }

    /**
     * 绘制实体左联结
     * @param entityUuid 实体对象uuid
     * @param uuidJoin
     */
    drawEntityLeftJoin (entityUuid: string, uuidJoin: string) {
      // 取得实体的基础实体
      const entity = this.modelManger.getEntityByUuid(entityUuid)
      if (!entity) return
      if (!entity.joins || entity?.joins.length === 0) return // 没有联结信息
      const join = this.modelManger.getEntityJoinByUuid(entityUuid, uuidJoin)
      if (!join) return
      const linkOb = document.getElementById(join.joinFieldUuid)
      if (!linkOb) return

      if (this.jsplumbHelper.checkLinkIsExist(entityUuid, join.joinEntityUuid)) return
      this.jsplumbHelper.createEntityLeftJoin(entityUuid, join.joinEntityUuid)
    }

    /**
     * 绘制实体字段的左联结  当前不使用
     * @param field 字段对象
     */
    drawFieldLeftJoin (entityUuid: string, fieldUuid: string) {
      // 取得实体的基础实体
      const entity = this.modelManger.getEntityByUuid(entityUuid)
      if (!entity) return
      if (!entity.joins || entity?.joins.length === 0) return // 没有联结信息

      const field = this.modelManger.getFieldByUuid(entityUuid, fieldUuid)
      if (!field) return
      if (!field.linkField) return

      const join = this.modelManger.getEntityJoinByUuid(entityUuid, field.joinUuid!)
      if (!join) return

      // 检查join是不是已经绘制
      // this.jsplumbHelper.checkLinkIsExist()

      // 检查被联结的实体是否在视图中
      const linkOb = document.getElementById(join.joinFieldUuid)
      if (linkOb) {
        const isLeftjoin = field.uuid === join.mainFieldUuid
        this.jsplumbHelper.createFieldJoinLink(field.uuid, field.joinFieldUuid!, isLeftjoin)
      }
    }

    /// 绘制当前视图
    drawCurView (on: any) {
      this.clear()

      // 绘制所有的实体对象
      for (const uuid in this.viewManger.entityPos) {
        const entity = this.modelManger.getEntityByUuid(uuid)
        if (!entity) continue

        this.drawEntity(entity, on, this.curOrgId, false)
      }

      this.vm.$nextTick(() => {
        for (const uuid in this.viewManger.entityPos) {
          // 绘制所有的实体派生
          this.drawEntityExtends(uuid)

          const entity = this.modelManger.getEntityByUuid(uuid)
          // 绘制所有的leftjoin
          if (entity && entity.joins && entity.joins.length > 0) {
            for (const join of entity.joins) {
              this.drawEntityLeftJoin(uuid, join.uuid)
            }
          }
        }
      })
    }

    /**
   * 取得视图中以从这个实体派生的所有实体的位置信息
   * @param uuid 实体的uuid
   */
    getFromExtendEntityPos (uuid: string): EntityPosition[] {
      const arr: EntityPosition[] = []
      for (const key in this.viewManger.entityPos) {
        const entity = this.modelManger.getEntityByUuid(key)
        if (entity?.baseEntityUuid && entity.baseEntityUuid === uuid) {
          const pos = this.viewManger.entityPos[key]
          arr.push(pos)
        }
      }
      return arr
    }

    /**
     * 取得当前的视图对象
     */
    get viewMangerInstance () {
      return this.viewManger
    }

    /**
     * 取得当前的组织id
     * @returns
     */
    getOrgId () {
      return this.curOrgId
    }

    /**
     * 清除ui对象
     */
    clear () {
      this.jsplumbHelper.clear()
    }

    /// 取得底层的jplumb对象
    get jplumbInstance () {
      return this.jsplumbHelper.jplumbInstance
    }
}
