
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import EntityItem from './entity-item.vue'
import { getterActiveEntityUuid, getterLockOrgIds } from '@/store'
import { getShortUuid } from '@/libs/uuid'
import EntityField from '@/libs/entitymodel/entity-field'
import Entity from '@/libs/entitymodel/entity'
import { EntityPosition } from '@/libs/entitymodel/entity-model-view'
import { SlickList, SlickItem } from 'vue-slicksort'
import EntityModelManger from '@/libs/entitymodel'
/**
 * 实体对象基础panle
 */
@Component({
  name: 'baseEntityPanel',
  components: { EntityItem, SlickList, SlickItem }
})
export default class BaseEntityPanel extends Vue {
  // 位置
  @Prop({
    type: Object,
    required: false,
    default: () => {
      return {
        top: 50,
        left: 10
      }
    }
  })
  pos!: EntityPosition;

  @Prop({
    type: String,
    required: true
  })
  viewOrgId!: string;

  curPos: EntityPosition = this.pos;

  /// 是否链接
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  link!: boolean;

  /**
   * 实体对象
   */
  @Prop({
    type: Object,
    required: true
  })
  readonly entity!: Entity;

  /**
   * 实体管理器
   */
  @Prop({
    type: Object,
    default: () => ({}),
    required: false
  })
  readonly manager!: EntityModelManger;

  /**
   * 当前是否只读
   */
  readOnly = this.lockedOrgIds.findIndex(item => item === this.pos.orgUuid) < 0;

  /// 新增实体计数
  newFieldCount = 0;

  /// 展开折叠状态
  get expended () {
    return !!this.curPos.expended
  }

  /// 当前选择项的索引
  activeFieldUuid = '';

  /// 是否可拖动排序
  slickable = false;

  /// 是否批量操作状态
  isBatch = false;
  /// 批量选择列表
  batchList: number[] = [];
  /// 是否拓展面板
  expend = false;

  /**
   * 当前实体是否已经选择
   */
  get curActive () {
    const curActive = getterActiveEntityUuid() === this.entity.uuid
    if (!curActive) {
      this.expend = false
    }
    return curActive
  }

  /// 切换开关
  tagge () {
    this.$set(this.curPos, 'expended', !this.curPos.expended)
    this.$emit('tagge', this.pos.uuid, this.pos.expended)
  }

  /// 处理字段选择事件
  onItemActive (id: string) {
    this.activeFieldUuid = id
    const index = this.entity.fields.findIndex(item => item.uuid === id)
    if (index >= 0) {
      this.$emit('onFieldActive', {
        field: this.entity.fields[index],
        entity: this.entity
      })
    }
  }

  // 处理字段删除事件
  onItemDelete (index: number) {
    this.$Modal.confirm({
      title: '是否删除？',
      content: '是否删除该字段？',
      onOk: () => {
        this.entity.fields.splice(index, 1)
      }
    })
  }

  onExpendchange () {
    this.expend = !this.expend
  }

  /// 当前主体显示状态
  get cotextDisplay () {
    return this.expended ? 'flex' : 'none'
  }

  /**
   * 取得指定字段对象
   */
  getELByFieldByIndex (index: number) {
    const node: Element = this.$refs.entitybody as Element
    return node.children[index]
  }

  /**
   * 选择当前对象
   */
  selectPanel () {
    if (this.entity) {
      this.$emit('onEntityActive', this.entity)
    }
  }

  /// 图标
  get icon () {
    if (this.entity.type === 'Entity') {
      return 'iconfont iconentity'
    }
    if (this.entity.type === 'View') {
      return 'iconfont iconlink_entity_gray'
    }
    if (this.entity.type === 'SupperEntity') {
      return 'iconfont iconentity_supper_gray'
    }
  }

  /// 添加一个新的字段
  addNewField () {
    const uuid = getShortUuid()
    this.newFieldCount++

    const field: EntityField = {
      uuid: uuid,
      name: 'f' + new Date().getTime(),
      title: '新字段:' + this.newFieldCount,
      primaryKey: false,
      type: 'String',
      nullable: true,
      insertable: true,
      updateable: true,
      transient: false,
      linkField: false,
      generatedValue: false
    }
    this.entity.fields.push(field)
  }

  /**
   * 取得当前工程的锁定组织
   */
  get lockedOrgIds () {
    return getterLockOrgIds()
  }

  /**
   * 监视锁定列表变化
   */
  @Watch('lockedOrgIds')
  WatchLockedOrgIds (value: string[]) {
    /// 检查当前是否已经锁住模型
    this.readOnly =
      this.lockedOrgIds.findIndex(item => item === this.pos.orgUuid) < 0
  }

  created () {
    this.WatchLockedOrgIds(this.lockedOrgIds)
  }

  /**
   * 拖动排序完成
   */
  onSlickFinish (field: any) {
    this.slickable = false
  }

  /**
   * 开始拖动排序
   */
  onStartSlick () {
    this.slickable = true && !this.readOnly
  }

  /**
   * 进入批量操作
   */
  onBatchSelect () {
    this.isBatch = true
  }

  /**
   * 退出批量操作
   */
  onBackBatchSelect () {
    this.isBatch = false
  }

  /**
   * 复制字段回调
   */
  onCopyField (index: number) {
    this.manager.cacheFields([this.entity.fields[index]])
  }

  /**
   * 粘贴字段回调
   */
  onPasteField () {
    this.manager.pasteFields(this.entity.uuid)
  }

  /**
   * 批量复制回调
   */
  onCacheFieldsBatchSelect () {
    if (this.batchList && this.batchList.length > 0) {
      this.manager.cacheFields(this.batchList.map(e => this.entity.fields[e]))
      this.batchList = []
      this.isBatch = false
    }
  }
}
