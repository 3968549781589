import EntityModelManger from '@/libs/entitymodel'
import { showModal } from '@/libs/modal-helper'
import modal from './add-linkentity-modal.vue'

/**
 * 弹出新增约束对话框
 * @param parent
 * @param onOk
 */
export default function showSelectLinkEntityModal (
  entityModelManger: EntityModelManger,
  orgUuid: string,
  onOk?: (selEntityUuid: string) => Promise<void>) {
  showModal<string>(modal, {
    props: {
      entityModelManger: entityModelManger,
      orgUuid: orgUuid
    }
  }, true, onOk, undefined)
}
